import { IEosAction } from "@pro/common/eos";
import { app, eos, world } from "../../App";
import { StakedAssetModel } from "../../stores/StakedAssetModel";
import { EosActionBase } from "../EosActionBase";

const BATCH_SIZE = 25;

export class ClaimManaAllAlction extends EosActionBase
{
	private readonly allAssets: StakedAssetModel[];

	constructor()
	{
		super("claimmanaall");

		this.allAssets = app.store.stakedAssets.query(it => it.actionAvailable);
	}

	async onExecute()
	{
		let userName = app.store.userName;

		for (let i = 0; i < this.allAssets.length; i += BATCH_SIZE) {
			const actions = new Array<IEosAction>();
			const batchAmount = Math.min(BATCH_SIZE, this.allAssets.length - i);
			for (let j = 0; j < batchAmount; j++) {
				const action = eos.tbContract.makeAction("claimmana", {actor: userName, permission: "active"}, {
					asset_id: this.allAssets[i + j].assetId,
					owner: userName,
				});
				actions.push(action);
			}

			await app.eos.eosApi.transact(actions);
		}
	}

	async onSelfUpdate()
	{
		await app.worldService.updateStakedAssets();
		await world.updateBalance(app.chainConf.MN_ACCOUNT, app.chainConf.MANA_TOKEN);
		await world.mustUpdatePoolBalance();
	}
}