import { observer } from "mobx-react";
import React, { useState } from "react";
import { app } from "../../../App";
import { ClaimManaAllAlction } from "../../../eos/actions/ClaimManaAllAlction";
import { L } from "../../../l10n/L10n";
import { GameButton, GameButtonStyleType } from "../GameButton";

const EnchantAllBtn = observer((
	{
		style,
	}: {
		style: GameButtonStyleType,
	},
) => {
	let [btnReady, setBtnReady] = useState(true);

	const readyToClaimAssets = app.store.stakedAssets.query(it => it.actionAvailable);

	async function onBtnClick()
	{
		new ClaimManaAllAlction()
			.setReadyStateAction(setBtnReady)
			.onSuccess(() => app.showInfo(L.common.success))
			.onError(() => app.worldService.updateStakedAssets())
			.executeAsync();
	}

	return (
		<GameButton
			text={L.gameHome.enchant_all}
			style={style}
			onClick={onBtnClick}
			ready={btnReady}
			disabled={readyToClaimAssets.length === 0}
		/>
	);
});

export default EnchantAllBtn;