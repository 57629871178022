import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { app, appStore } from "../../App";
import { ClaimManaAction } from "../../eos/actions/ClaimManaAction";
import { formatTimeLeft } from "../../helpers/formatters";
import { L } from "../../l10n/L10n";
import { StakedAssetModel } from "../../stores/StakedAssetModel";
import { Spacer } from "../Spacer";
import AssetViewStaked from "./AssetViewStaked";
import Styles from "./EnchantAssetView.module.scss";
import { GameButton, GameButtonStyleType } from "./GameButton";
import EnchantAllBtn from "./home/EnchantAllBtn";
import { PageTitle } from "./PageTitle";

const CardInfoLine = ({title, value, vertical}: {title: string, value: number | string, vertical?: boolean}) => (
	<div className={classNames(Styles.styleHeroPackCardInfoLineContainer, {[Styles.styleFlexVertical]: vertical})}>
		<div className={Styles.styleHeroPackCardInfoKey}>
			{title}
		</div>
		<div className={classNames(Styles.styleHeroPackCardInfoValue, {[Styles.styleTextLeft]: vertical})}>
			{`${value}`}
		</div>
	</div>
);

export const EnchantAssetView = observer(() => {
	const [btnReady, setBtnReady] = useState(true);

	const isMediumScreen = useMediaQuery({query: "(min-width: 768px)"});

	const asset = app.store.activeCard;

	const isManaLeftInPool = app.store.poolBalance > 0;

	useEffect(() => {
		setBtnReady(asset !== null);
	}, [app.store.activeCard]);

	function onEnchant()
	{
		const activeAsset = appStore.activeCard;
		if (!activeAsset)
			return;

		new ClaimManaAction(activeAsset.assetId)
			.setReadyStateAction(setBtnReady)
			.onSuccess(() => app.showInfo(L.common.success))
			.executeAsync();
	}

	if (!asset) {
		return <div className={Styles.styleHeroPackContainer}>Click on the card to see the details</div>;
	}

	if (!isMediumScreen) {
		return (
			<SmallLayout
				asset={asset}
				name={asset.name}
				rarity={asset.rarity}
				power={asset.power}
				onEnchant={onEnchant}
				btnReady={btnReady}
				disabled={formatTimeLeft(asset.secondsToAction) !== "00:00:00"}
				isManaLeftInPool={isManaLeftInPool}
			/>
		);
	}

	return (
		<BigLayout
			name={asset.name}
			rarity={asset?.rarity}
			power={asset.power}
			onEnchant={onEnchant}
			btnReady={btnReady}
			disabled={formatTimeLeft(asset.secondsToAction) !== "00:00:00"}
			isManaLeftInPool={isManaLeftInPool}
		/>
	);
});

type LayoutPropsType = {
	name: string
	rarity: string
	power: number
	onEnchant: () => void
	btnReady: boolean
	disabled: boolean
	isManaLeftInPool: boolean
}

type SmallLayoutPropsType = LayoutPropsType & {
	asset: StakedAssetModel
}

const SmallLayout = ({
	                     asset,
	                     name,
	                     rarity,
	                     power,
	                     onEnchant,
	                     btnReady,
	                     disabled,
	                     isManaLeftInPool,
                     }: SmallLayoutPropsType) => (
	<div className={Styles.styleHeroPackContainer}>
		<div className={Styles.styleHeroPackImage}>
			<div className={Styles.styleHeroPackCardInfoExtra}>

				<div className={Styles.styleHeroPackSummaryContainerSmall}>
					<AssetViewStaked mode="ONLY_STAKED" asset={asset} key={`${asset.assetId}`} preview/>
					<div className={Styles.styleHeroPackSummary}>
						<CardInfoLine title={L.gameHome.name} value={name}/>
						<CardInfoLine title={L.gameHome.rarity} value={rarity}/>
						<Spacer size={40}/>
						<div className={Styles.styleChargeTimeContiner}>
							<CardInfoLine title={L.gameHome.cargeTime} value={"24 hours"} vertical/>
							<CardInfoLine title={L.gameHome.enchantingPower} value={power} vertical/>
						</div>
						<div className={Styles.smallButtonContainer}>
							{isManaLeftInPool
								? <>
									<GameButton
										text={L.gameHome.enchant}
										style={GameButtonStyleType.SMALL}
										onClick={onEnchant}
										ready={btnReady}
										disabled={disabled}
									/>
									<EnchantAllBtn style={GameButtonStyleType.SMALL}/>
								</>
								: <NoManaLeftView/>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const BigLayout = ({name, rarity, power, onEnchant, btnReady, disabled, isManaLeftInPool}: LayoutPropsType) => (
	<div className={Styles.styleHeroPackContainer}>
		<div className={Styles.styleHeroPackImage}>
			<PageTitle title={name}/>
			<div className={Styles.styleHeroPackSummaryContainer}>
				<div className={Styles.styleHeroPackSummary}>
					<CardInfoLine title={L.gameHome.rarity} value={rarity}/>
					<CardInfoLine title={L.gameHome.cargeTime} value={"24 hours"}/>
					<CardInfoLine title={L.gameHome.enchantingPower} value={power}/>
				</div>
				<div className={Styles.bigButtonContainer}>
					{isManaLeftInPool
						? <>
							<GameButton
								text={L.gameHome.enchant}
								style={GameButtonStyleType.SMALL}
								onClick={onEnchant}
								ready={btnReady}
								disabled={disabled}
							/>
							<EnchantAllBtn style={GameButtonStyleType.MIDDLE}/>
						</>
						: <NoManaLeftView/>
					}
				</div>
			</div>
		</div>
	</div>
);

const NoManaLeftView = () => (
	<h3 className={Styles.noManaLeft}>There is no mana left in the pool.</h3>
);
