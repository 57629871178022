import { observer } from "mobx-react";
import React from "react";
import { app } from "../../App";
import { AssetExaminer } from "../../components/game/AssetExaminer";
import { EnchantAssetView } from "../../components/game/EnchantAssetView";
import GameLayout from "../../components/game/GameLayout";
import { NoCardsView } from "../../components/game/NoCardsView";
import { useCards } from "../../hooks/useCards";
import { useLoadGameData } from "../../hooks/useLoadGameData";
import { L } from "../../l10n/L10n";
import { StakedAssetModel } from "../../stores/StakedAssetModel";
import Styles from "./home.module.scss";

const GameHomePage = observer(() => {
	const allData = useCards({ useUnstakeOnly: true })
	useLoadGameData();

	const stakedAssets: StakedAssetModel[] = app.store.stakedAssets.queryAll();

	const Content = () => (
		<>
			<div className={Styles.styleHomeCardsContainer}>
				<AssetExaminer mode={"ONLY_STAKED"} assetsToShow={allData} />
			</div>
			<div className={Styles.styleHomeInfoContainer}>
				<EnchantAssetView />
			</div>
		</>
	)

	return (
		<GameLayout title={L.gameHome.title} description={L.gameHome.desc}>
			<div className={Styles.styleHomeContainer}>
				{stakedAssets.length ? <Content /> : <NoCardsView /> }
			</div>
		</GameLayout>
	)
})

export default GameHomePage;
