import { TUint64 } from "@pro/common/contracts/atomicassets";
import { app, eos, world } from "../../App";
import { EosActionBase } from "../EosActionBase";

export class ClaimManaAction extends EosActionBase
{
	constructor(private asset_id: TUint64)
	{
		super("claim_mana");
	}

	async onExecute()
	{
		await eos.claimMana(this.asset_id);
	}

	async onSelfUpdate()
	{
		await world.updateStakedAsset(this.asset_id);
		await world.updateBalance(app.chainConf.MN_ACCOUNT, app.chainConf.MANA_TOKEN);
		await world.mustUpdatePoolBalance();
	}
}